@tailwind base;
@tailwind components;
@tailwind utilities;

/* Fonts */
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jersey+25&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,opsz,wght@0,9..40,100..1000;1,9..40,100..1000&display=swap');

/* Custom CSS */

html {
  scroll-behavior: smooth;
}

*, *::before, *::after {
  box-sizing: border-box;
}

img, picture, svg, video {
  display: block;
  max-width: 100%;
  height: auto;
}

html, body {
  min-width: 360px; /* Set your desired minimum width */
  overflow-x: auto; /* Allow horizontal scrolling if content exceeds the viewport width */
}


:root {
    --bg_opacity: 0.6;
    --bg_gradient: linear-gradient(to right bottom, #051937, #004d7a, #008793, #00bf72, #a8eb12);
    --custom_blue: #021117; /* RGB(2, 17, 23) */
    --custom_blue_light: #0d5b80; /* RGB(13, 91, 128) */
    --custom_blue_lighter: #17a6e8; /* RGB(23, 166, 232) */
    --custom_blue_medium: #073144; /* RGB(7, 49, 68) */
    --custom_lime: #D4DE29; /* RGB(212, 222, 41) */
    --custom_lime_dark: #7f8415; /* RGB(127, 132, 21) */
}

.custom_pattern{
  background-color: var(--custom_blue_light);
  opacity: 1;
  background-image: radial-gradient(rgba(2, 17, 23, 0.25) 1.1500000000000001px, rgba(7,49,68, 1) 1.1500000000000001px);
  background-size: 23px 23px;
}

.custom_pattern_blue{
  background-color: var(--custom_blue);
  opacity: 1;
  background-image: radial-gradient(rgba(13, 91, 128, 0.15) 1.1500000000000001px, rgba(2, 17, 23, 1) 1.1500000000000001px);
  background-size: 23px 23px;
}

.custom_gradient {
    background: linear-gradient(90deg, rgba(12,70,98,1) 0%, rgba(13,91,128,1) 100%);
}

.custom_gradient_dark{
  background: linear-gradient(90deg, rgba(2,17,23,1) 0%, rgba(3,20,30,1) 100%);
}

/* Fonts */

.roboto-mono-font{
  font-family: "Roboto Mono", monospace;
  font-optical-sizing: auto;
  font-style: normal;
}

.comfortaa-font{
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
}

.jersey-25-regular-font{
  font-family: "Jersey 25", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.dm-sans-regular-font{
  font-family: "DM Sans", sans-serif;
  font-optical-sizing: auto;
}

/* Main CSS */

#socials>li{
  transition: opacity 0.25s;
}

#socials>li:hover{
  opacity: 0.5;
}

.menu a li{
  transition: background-color 0.15s;
}

.tech_icons i{
  width: 200px;
  padding: 0.5rem 0.75rem;
  border-radius: 9999px;
  background-color: var(--custom_blue);
  color: var(--custom_lime);
  font-size: 1.1rem;
  display: flex;
  user-select: none;
  transition: background-color 0.15s;
}

.tech_icons i span{
  /* font-sans ms-2 whitespace-nowrap */
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
  flex-grow: 1;
  color: white;
  opacity: 1;
}

.tech_icons i:hover{
  background-color: var(--custom_lime);
  color: var(--custom_blue);
  span{
    color: var(--custom_blue);
  }
}

@media screen and (max-width: 1200px){
  #main_img{
    bottom: auto;
    right: 0;
    top: 1rem;
    height: 500px;
  }

  #main_heading{
    font-size: 6rem;
  }  

  #sub_heading{
    font-size: 1.25rem;
  }

  #main_section{
    width: 75%;
  }
}

@media screen and (max-width: 800px){
  #main_img{
    height: 400px;
  }

  #main_heading{
    font-size: 4rem;
  }

  #sub_heading{
    font-size: 0.9rem;
  }

  #main_section{
    width: 100%;
  }

  #top_content{
    width: 100% !important;
    margin-bottom: 20rem;
  }

  .header{
    padding-top: 1.25rem;
  }

  .menu{
    width: 100%;
    grid-column: 1 / 3;
    grid-template-rows: auto auto auto auto;
    grid-template-columns: auto;
    justify-items: start;
    margin: 0;
  }

  .menu a, .menu a li{
    width: 100%;
  }

  .menu a li{
    padding: 1rem !important;
    background-color: var(--custom_blue_medium);
    border-radius: 0;
  }

  #bottom_content{
    width: 100%;
    padding: 0;
    background-color: transparent;
  }

  #about_txt{
    margin: 2.5rem 0 0 0;
    background: rgb(12,70,98);
    background: linear-gradient(90deg, rgba(12,70,98,1) 0%, rgba(13,91,128,1) 100%);
    padding: 2.5rem;
  }

  .header ul {
    margin: 0 !important;
  }

  .tech_icons i{
    width: 150px;
    padding: 0.25rem 0.5rem;
    font-size: 1rem;
  }
}

/* Testing */

.header ul {
  margin: 0 1.25rem 0 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}

.header a li {
  display: block;
  padding: 20px 20px;
  margin-left: 0px;
  text-decoration: none;
}

.header .logo {
  display: block;
  float: left;
  font-size: 2em;
  padding: 10px 20px;
  text-decoration: none;
}

/* menu */

.header .menu {
  clear: both;
  max-height: 0;
  transition: max-height .25s ease-in-out;
}

/* menu icon */

.header .menu-icon {
  cursor: pointer;
  display: inline-block;
  
  position: relative;
  user-select: none;
}

.header .menu-icon .navicon {
  background: var(--custom_lime);
  display: block;
  height: 2px;
  position: relative;
  transition: background .2s ease-out;
  width: 18px;
}

.header .menu-icon .navicon:before,
.header .menu-icon .navicon:after {
  background: var(--custom_lime);
  content: '';
  display: block;
  height: 100%;
  position: absolute;
  transition: all .2s ease-out;
  width: 100%;
}

.header .menu-icon .navicon:before {
  top: 5px;
}

.header .menu-icon .navicon:after {
  top: -5px;
}

/* menu btn */

.header .menu-btn {
  display: none;
}

.header .menu-btn:checked ~ .menu {
  max-height: 300px;
}

.header .menu-btn:checked ~ .menu-icon .navicon {
  background: transparent;
}

.header .menu-btn:checked ~ .menu-icon .navicon:before {
  transform: rotate(-45deg);
}

.header .menu-btn:checked ~ .menu-icon .navicon:after {
  transform: rotate(45deg);
}

.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:before,
.header .menu-btn:checked ~ .menu-icon:not(.steps) .navicon:after {
  top: 0;
}

/* 48em = 768px */

@media (min-width: 48em) {
  .header li {
    float: left;
  }
  .header a li {
    padding: 20px 30px;
  }
  .header .menu {
    clear: none;
    float: right;
    max-height: none;
  }
  .header .menu-icon {
    display: none;
  }
}

/* Blog posts */

.post{
  h1, h2, h3{
    color: var(--custom_lime);
    font-weight: 600;
    margin: 0;
  }

  h1{
    font-size: 2rem;
    margin-top: -15px;
    border-bottom: var(--custom_lime) 2px solid;
    margin-bottom: 1rem;
    font-weight: 700;
  }

  h2{
    font-size: 1.5rem;
  }

  h3{
    font-size: 1.25rem;
  }

  ul, ol{
    margin-left: 2rem;
  }

  ul{
    list-style-type: disc;
  }

  ol{
    list-style-type: decimal;
  }
}

.blog_tags{
  scrollbar-width: thin;
  scrollbar-color: var(--custom_lime) var(--custom_blue);
}